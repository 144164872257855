import React from 'react';
import Collapse, { Panel } from 'rc-collapse';

import styles from './Faq.module.scss';
import './rc-collapse.scss';
// require('rc-collapse/assets/index.css');
import FaqImgUrl from '../../../images/faq/faq-1.svg';
import { ReactComponent as IconArrow } from '../../../images/icons/arr-btn.svg';

const faqList = [
  {
    id: 1,
    question: 'Sed ut perspiciatis, unde omnis iste natu?',
    answer:
      'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit. ',
  },
  {
    id: 2,
    question: 'Sed ut perspiciatis, unde omnis iste natu?',
    answer:
      'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit. ',
  },
  {
    id: 3,
    question: 'Sed ut perspiciatis, unde omnis iste natu?',
    answer:
      'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit. ',
  },
  {
    id: 4,
    question: 'Sed ut perspiciatis, unde omnis iste natu?',
    answer:
      'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit. ',
  },
  {
    id: 5,
    question: 'Sed ut perspiciatis, unde omnis iste natu?',
    answer:
      'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit. ',
  },
];

const expandIcon = () => (
  <div className="rc-collapse-arrow">
    <IconArrow />
  </div>
);
const Faq = () => {
  return (
    <section className={styles.sectionFaq}>
      <h1 className={styles.img}>
        <img src={FaqImgUrl} alt="FAQ" />
      </h1>

      <div className={styles.accList}>
        <Collapse accordion expandIcon={expandIcon}>
          {faqList.map(({ id, question, answer }) => (
            <Panel key={id} header={question}>
              {answer}
            </Panel>
          ))}
        </Collapse>
      </div>
    </section>
  );
};

export default Faq;
