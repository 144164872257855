import React from 'react';
import Page from '../components/layout/Page';
import Faq from '../components/layout/faq/Faq';
import HomeStart from '../components/layout/home/HomeStart';

const FAQPage = () => (
  <Page title="FAQ">
    <Faq />
    <HomeStart />
  </Page>
);

export default FAQPage;
