import React from 'react';
import RButton from '../../common/RButton';
import styles from './HomeStart.module.scss';
import { ReactComponent as IconNoCard } from '../../../images/icons/no-card.svg';
import StartImgUrl from '../../../images/home/home-get-started.svg';
import { signUpUrl } from '../../../config/settings';

const HomeStart = () => {
  return (
    <section className={styles.sectionHomeStart}>
      <div className={styles.img}>
        <img src={StartImgUrl} alt="" />
      </div>
      <h2>
        <strong>Get started</strong> with BOOST today
      </h2>
      <div className={styles.action}>
        <a href={signUpUrl}>
          <RButton color="secondary" btnSize={50} rSize={40} iconSize={7} iconType={1} btnText="Try it free" />
        </a>
        <div className={styles.nocard}>
          <IconNoCard className={styles.icon} width={20} />
          <span>No credit card required</span>
        </div>
      </div>
    </section>
  );
};

export default HomeStart;
