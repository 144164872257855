import React from 'react';
import classnames from 'classnames';
import styles from './RButton.module.scss';
import { ReactComponent as Icon1 } from '../../images/icons/arr-btn.svg';
import { ReactComponent as Icon2 } from '../../images/icons/arr-btn-2.svg';

const colorToClassNameMap = {
  inverted: styles.inverted,
  primary: styles.primary,
  secondary: styles.secondary,
  success: styles.success,
  danger: styles.danger,
};

const RButton = ({ onClick, className, color = 'primary', btnSize, rSize, iconSize, iconType, btnText, ...rest }) => {
  const btnStyle = { height: `${btnSize}px` };
  const rStyle = { width: `${rSize}px`, height: `${rSize}px` };
  const iconWidth = `${iconSize}px`;

  const iconTypeToSVGMap = {
    1: <Icon1 width={iconWidth} />,
    2: <Icon2 width={iconWidth} />,
  };

  return (
    <button
      type="button"
      className={classnames(styles.btn, colorToClassNameMap[color], className)}
      onClick={onClick}
      style={btnStyle}
      {...rest}
    >
      <span className={styles.btnTxt}>{btnText}</span>
      <span className={classnames(styles.btnIcon)} style={rStyle}>
        {iconTypeToSVGMap[iconType]}
      </span>
    </button>
  );
};

export default RButton;
